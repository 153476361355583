<template>
 <div>
  <svg id="Laag_1" style="enable-background:new 0 0 183 183;" version="1.1" viewBox="0 0 183 183" x="0px" xml:space="preserve" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" y="0px">
   <defs>
    <linearGradient gradientUnits="userSpaceOnUse" id="wifi-2_00000155848369255983152550000015095442348370504615_" x1="138.5073" x2="40.5031" y1="67.4022" y2="149.6375">
     <stop :stop-color="color1" offset="0"/>
     <stop :stop-color="color2" offset="1"/>
    </linearGradient>
   </defs>
   <rect :fill="background" height="183" width="183"/>
   <path d="  M-308.1-434.6h-100.8c-4.3,0-7.7,3.5-7.7,7.7v36.1c0,4.3,3.5,7.7,7.7,7.7h100.8c4.3,0,7.7-3.5,7.7-7.7v-36.1  C-300.4-431.1-303.8-434.6-308.1-434.6z M-370.6-420.4l-6.4,24.8c-0.2,0.8-0.6,1.4-1.3,1.8c-1.4,1-3.4,0.6-4.4-0.8l-5.8-8.5  l-5.8,8.5c-0.4,0.6-1.1,1.1-1.8,1.3c-1.7,0.4-3.4-0.6-3.9-2.3l-6.4-24.8c0-0.1,0-0.2-0.1-0.2c-0.4-1.7,0.7-3.4,2.4-3.8  c1.7-0.4,3.4,0.7,3.8,2.4l4.7,18l4.5-6.6c0.2-0.3,0.5-0.6,0.8-0.8c1.5-1,3.4-0.6,4.4,0.8l4.5,6.6l4.7-18c0.4-1.7,2.2-2.7,3.9-2.3  C-371.1-423.8-370.1-422.1-370.6-420.4z M-355.4-396c0,1.7-1.4,3.2-3.2,3.2c-1.7,0-3.2-1.4-3.2-3.2v-25.5c0-1.7,1.4-3.2,3.2-3.2v0  c1.8,0,3.2,1.4,3.2,3.2V-396z M-328.8-407.4h-9v11.3c0,1.8-1.4,3.2-3.2,3.2c-1.7,0-3.2-1.4-3.2-3.2v-25.5c0-1.8,1.4-3.2,3.2-3.2  h12.2c1.8,0,3.2,1.4,3.2,3.2c0,1.7-1.4,3.1-3.1,3.2h-9.1v4.7h9.1c1.8,0,3.2,1.4,3.2,3.2C-325.7-408.9-327.1-407.4-328.8-407.4z   M-310.9-396.1c0,1.8-1.4,3.2-3.1,3.2c-1.8,0-3.2-1.4-3.2-3.2v-25.5c0-1.8,1.4-3.2,3.2-3.2h0c1.7,0,3.1,1.4,3.1,3.2V-396.1z   M-382.5-457.3L-382.5-457.3c1.5,1.2,3.8,1,5-0.5c0,0,0,0,0,0c8.6-10.5,24-12,34.5-3.4c1.2,1,2.3,2.1,3.3,3.2c1.3,1.5,3.5,1.8,5,0.5  c0,0,0,0,0,0l0,0c1.8-1.6,2.1-4.4,0.4-6.3c-11.2-13.4-31.2-15.3-44.6-4.1c-1.6,1.3-3,2.7-4.3,4.3  C-384.6-461.7-384.4-458.9-382.5-457.3L-382.5-457.3z M-394.9-467.3L-394.9-467.3c1.5,1.3,3.8,1,5-0.5c0,0,0.1-0.1,0.1-0.1  c7.4-9.7,18.9-15.5,31.1-15.7c12.2,0.1,23.7,5.8,31.2,15.4c1.2,1.6,3.4,1.9,5,0.7c0,0,0.1-0.1,0.1-0.1l0,0c1.8-1.6,2.1-4.3,0.6-6.2  c-8.9-11.4-22.5-18.2-37-18.3c-14.5,0.2-28,7.1-36.8,18.6C-397-471.6-396.7-468.9-394.9-467.3L-394.9-467.3z M-358.6-457.3  c4,0,7.3,3.3,7.3,7.3c0,4-3.3,7.3-7.3,7.3c-4,0-7.3-3.3-7.3-7.3C-365.9-454.1-362.6-457.3-358.6-457.3z" id="wifi-2" style="fill:url(#wifi-2_00000155848369255983152550000015095442348370504615_);" transform="translate(450 529.042)"/>
  </svg>
 </div>
</template>
<script>
export default {
  props: ["color1", "color2", "background"],
};
</script>